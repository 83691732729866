import React, { FC } from "react";
import { spaceRem } from "../global-style";

type Props = {
  html: string;
};

export const HtmlContent: FC<Props> = props => (
  <div
    css={`
      * + * {
        margin-top: ${0.5 * spaceRem}rem;
      }
    `}
    dangerouslySetInnerHTML={{ __html: props.html }}
  />
);

import React, { FC } from "react";
import { motion, AnimationControls } from "framer-motion";

export const PlatformGradient: FC<{
  animationControls: AnimationControls;
}> = props => (
  <>
    <motion.stop
      offset={0.13}
      animate={props.animationControls}
      initial="idle"
      variants={{
        idle: { stopColor: "#949799" },
        active: {
          stopColor: ["#949799", "#3a84b6", "#949799"],
          transition: {
            duration: 2,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          },
        },
      }}
    />
    <motion.stop
      offset={0.17}
      animate={props.animationControls}
      initial="idle"
      variants={{
        idle: { stopColor: "#9da0a1" },
        active: {
          stopColor: ["#9da0a1", "#3389c1", "#9da0a1"],
          transition: {
            duration: 2,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          },
        },
      }}
    />
    <motion.stop
      offset={0.35}
      animate={props.animationControls}
      initial="idle"
      variants={{
        idle: { stopColor: "#bebfc0" },
        active: {
          stopColor: ["#bebfc0", "#84c2eb", "#bebfc0"],
          transition: {
            duration: 2,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          },
        },
      }}
    />

    <motion.stop
      offset={0.5}
      animate={props.animationControls}
      initial="idle"
      variants={{
        idle: { stopColor: "#d2d2d2" },
        active: {
          stopColor: ["#d2d2d2", "#84c2eb", "#d2d2d2"],
          transition: {
            duration: 2,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          },
        },
      }}
    />

    <motion.stop
      offset={0.6}
      animate={props.animationControls}
      initial="idle"
      variants={{
        idle: { stopColor: "#d9d9d9" },
        active: {
          stopColor: ["#d9d9d9", "#84c2eb", "#d9d9d9"],
          transition: {
            duration: 2,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          },
        },
      }}
    />

    <motion.stop
      offset={0.7}
      animate={props.animationControls}
      initial="idle"
      variants={{
        idle: { stopColor: "#d2d3d3" },
        active: {
          stopColor: ["#d2d3d3", "#6eb7e7", "#d2d3d3"],
          transition: {
            duration: 2,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          },
        },
      }}
    />

    <motion.stop
      offset={0.83}
      animate={props.animationControls}
      initial="idle"
      variants={{
        idle: { stopColor: "#c0c1c2" },
        active: {
          stopColor: ["#c0c1c2", "#65b3e6", "#c0c1c2"],
          transition: {
            duration: 2,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          },
        },
      }}
    />

    <motion.stop
      offset={0.99}
      animate={props.animationControls}
      initial="idle"
      variants={{
        idle: { stopColor: "#a2a4a6" },
        active: {
          stopColor: ["#a2a4a6", "#42a1e0", "#a2a4a6"],
          transition: {
            duration: 2,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          },
        },
      }}
    />

    <motion.stop
      offset={1}
      animate={props.animationControls}
      initial="idle"
      variants={{
        idle: { stopColor: "#9fa1a3" },
        active: {
          stopColor: ["#9fa1a3", "#3198dd", "#9fa1a3"],
          transition: {
            duration: 2,
            ease: "easeInOut",
            loop: Infinity,
            repeatDelay: 1,
          },
        },
      }}
    />
  </>
);

import React, { FC, useContext, useRef, useEffect, useState } from "react";
import {
  spaceRem,
  colors,
  targetBaseFontSizePx,
  fontStacks,
} from "../global-style";
import { graphql, Link } from "gatsby";
import { HomeQuery } from "../../graphql-types";
import { css } from "styled-components";
import { LayoutContext } from "../components/layout";
import { HtmlContent } from "../components/html-content";
import { Arrow } from "../components/arrow";
import BackgroundImage from "gatsby-background-image";
import Img, { FluidObject } from "gatsby-image";
import {
  motion,
  useViewportScroll,
  useTransform,
  transform,
  useAnimation,
} from "framer-motion";
import { Platform1 } from "../components/platform-1";
import { Platform2 } from "../components/platform-2";
import { Platform3 } from "../components/platform-3";
import { Platform4 } from "../components/platform-4";
import { TakeADive } from "../components/take-a-dive";
import { useLocation } from "@reach/router";

export const query = graphql`
  query Home($id: String) {
    seaTransition: file(relativePath: { eq: "sea-transition.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sky: file(relativePath: { eq: "sky1.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    jellyfish: file(relativePath: { eq: "jellyfish.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    reef: file(relativePath: { eq: "reef.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    windTurbine: file(relativePath: { eq: "wind-turbine.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    oilRig: file(relativePath: { eq: "oil-rig.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    birds: file(relativePath: { eq: "birds.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    wpPage(id: { eq: $id }) {
      homeCustomFields {
        homeHero
        homeIntro {
          homeIntroButtonLabel
          homeIntroContent
          homeIntroHeading
          homeIntroTitle
          homeIntroPage {
            ... on WpPage {
              uri
            }
          }
        }
        homeEnergyAtlas {
          homeEnergyAtlasButtonLabel
          homeEnergyAtlasContent
          homeEnergyAtlasHeading
          homeEnergyAtlasTitle
          homeEnergyAtlasPage {
            ... on WpPage {
              uri
            }
          }
        }
        homeProgramme {
          homeProgrammeHeading
          homeProgrammeIntro
          homeProgrammeTitle
          homeProgrammeItems {
            homeProgrammeItemsName
            homeProgrammeItemsItem {
              ... on WpProgrammeItem {
                uri
              }
            }
            homeProgrammeItemsImage {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 300, maxHeight: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Home: FC<{ data: HomeQuery }> = props => {
  const { remInPx } = useContext(LayoutContext);

  const location = useLocation();
  const currentLanguageCode = location.pathname
    .split("/")
    .filter(v => v)[0]
    .toUpperCase() as "NL" | "EN";

  const sliderElRef = useRef<HTMLDivElement>(null);

  const [innerHeightPx, setInnerHeightPx] = useState(0);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setInnerHeightPx(window.innerHeight);
    }
  }, []);

  const { scrollY } = useViewportScroll();

  const introScrollYProgress = useTransform(scrollY, [300, 800], [0, 1]);

  const platform1Opacity = useTransform(
    introScrollYProgress,
    [0, 0.25],
    [0, 1]
  );
  const platform1TranslateY = useTransform(introScrollYProgress, v =>
    transform(v, [0, 0.25], [`-40%`, `0%`])
  );

  const platform2Opacity = useTransform(
    introScrollYProgress,
    [0.25, 0.5],
    [0, 1]
  );
  const platform2TranslateY = useTransform(introScrollYProgress, v =>
    transform(v, [0.25, 0.5], [`-70%`, `0%`])
  );

  const platform3Opacity = useTransform(
    introScrollYProgress,
    [0.75, 1],
    [0, 1]
  );
  const platform3TranslateY = useTransform(introScrollYProgress, v =>
    transform(v, [0.75, 1], [`-40%`, `0%`])
  );

  const platform4Opacity = useTransform(
    introScrollYProgress,
    [0.5, 0.75],
    [0, 1]
  );
  const platform4TranslateY = useTransform(introScrollYProgress, v =>
    transform(v, [0.5, 0.75], [`-60%`, `0%`])
  );

  const platformPulse = useAnimation();

  useEffect(() => {
    introScrollYProgress.onChange(v => {
      if (v >= 1) {
        platformPulse.start("active");
        return;
      }

      platformPulse.stop();
    });
  }, []);

  return (
    <>
      <div
        style={{
          height: innerHeightPx || "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* sky */}

        <BackgroundImage
          Tag="div"
          fluid={props.data.sky?.childImageSharp?.fluid as FluidObject}
          backgroundColor={`#8bc7d6`}
          style={{
            flexGrow: 1,
            flexBasis: 0,
            position: "relative",
            backgroundPosition: "bottom center",
          }}
        >
          <Img
            fluid={
              props.data.windTurbine?.childImageSharp?.fluid as FluidObject
            }
            style={{
              position: "absolute",
              pointerEvents: "none",
              width: "10%",
              bottom: 0,
              left: "7%",
              mixBlendMode: "multiply",
            }}
          />

          <Img
            fluid={
              props.data.windTurbine?.childImageSharp?.fluid as FluidObject
            }
            style={{
              position: "absolute",
              pointerEvents: "none",
              width: "3%",
              bottom: 0,
              left: "17%",
              mixBlendMode: "multiply",
            }}
          />

          <Img
            fluid={props.data.birds?.childImageSharp?.fluid as FluidObject}
            style={{
              position: "absolute",
              pointerEvents: "none",
              width: "60%",
              bottom: "40%",
              left: "20%",
              mixBlendMode: "multiply",
            }}
          />

          <Img
            fluid={props.data.oilRig?.childImageSharp?.fluid as FluidObject}
            style={{
              position: "absolute",
              pointerEvents: "none",
              width: "20%",
              bottom: 0,
              right: "10%",
              opacity: 0.8,
            }}
          />

          <div
            style={{
              position: "absolute",
              bottom: `${0.5 * spaceRem}rem`,
              width: "100%",
              textAlign: "center",
              color: colors.white,
            }}
          >
            <h1
              style={{
                hyphens: "none",
                maxWidth: "25ch",
                marginLeft: "auto",
                marginRight: "auto",
                paddingLeft: `${0.5 * spaceRem}rem`,
                paddingRight: `${0.5 * spaceRem}rem`,
              }}
              css={(() => {
                const minFontSizeRem = 1.8888;
                const maxFontSizeRem = 3.8888;
                const vwRangeStartPx = 400;

                return css`
                  font-size: ${(minFontSizeRem *
                    (remInPx || targetBaseFontSizePx)) /
                    (vwRangeStartPx / 100)}vw;

                  @media (max-width: ${vwRangeStartPx}px) {
                    font-size: ${minFontSizeRem}rem;
                  }

                  @media (min-width: ${vwRangeStartPx *
                      (maxFontSizeRem / minFontSizeRem)}px) {
                    font-size: ${maxFontSizeRem}rem;
                  }
                `;
              })()}
              dangerouslySetInnerHTML={{
                __html: props.data.wpPage?.homeCustomFields?.homeHero || "",
              }}
            />
          </div>
        </BackgroundImage>

        {/* sea */}
        <div
          style={{
            flexGrow: 1,
            flexBasis: 0,
            position: "relative",
          }}
        >
          <div
            style={{
              background: "linear-gradient(to top, #00728e, #1a97bd)",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />

          <video
            src="/sea-seamless.mp4"
            muted={true}
            autoPlay={true}
            loop={true}
            playsInline={true}
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              objectFit: "cover",
              height: "100%",
            }}
          />

          <Img
            fluid={
              props.data.seaTransition?.childImageSharp?.fluid as FluidObject
            }
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              minHeight: 150,
              width: "100%",
            }}
          />

          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "20vh",
              background: `linear-gradient(transparent 0%, ${colors.darkestBlue} 100%)`,
            }}
          />

          <div
            style={{
              position: "absolute",
              bottom: `${1 * spaceRem}rem`,
              width: "100%",
            }}
          >
            <button
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                border: "none",
                borderRadius: "50%",
                height: `${1.5 * spaceRem}rem`,
                width: `${1.5 * spaceRem}rem`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: colors.white,
                cursor: "pointer",
                outline: "none",
              }}
              onClick={() => {
                scrollTo({
                  top: typeof window !== "undefined" ? window.innerHeight : 0,
                  behavior: "smooth",
                });
              }}
            >
              <Arrow direction="down" />
            </button>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "1.5rem",
            right: "1.5rem",
            zIndex: 999,
            alignItems: "center",
            writingMode: "vertical-lr",
          }}
          css={`
            display: none;

            @media (min-width: 1200px) {
              display: flex;
            }
          `}
        >
          <p
            style={{
              paddingBottom: "2.8rem",
              color: colors.white,
              fontFamily: fontStacks.poppins,
              fontSize: "0.8rem",
              textTransform: "uppercase",
              letterSpacing: "1.25px",
              opacity: 0.5,
              cursor: "pointer",
            }}
            onClick={() => {
              scrollTo({
                top: typeof window !== "undefined" ? window.innerHeight : 0,
                behavior: "smooth",
              });
            }}
          >
            {(currentLanguageCode === "NL" && "neem een duik") ||
              (currentLanguageCode === "EN" && "take a dive")}
          </p>

          <svg
            viewBox="0 0 23.745 42.687"
            style={{
              position: "fixed",
              height: "2rem",
              bottom: "1.5rem",
            }}
          >
            <path
              d="M23.445 24.201c0-6.573-3.962-12.314-7.351-15.509l4.778-6.379a1.285 1.285 0 00.259-.776A1.143 1.143 0 0019.812.329l-7.94 1.768L3.933.33a1.143 1.143 0 00-1.319 1.207 1.285 1.285 0 00.259.776l4.778 6.38C4.261 11.887.3 17.628.3 24.201c0 10.043 9.258 18.185 11.573 18.185s11.572-8.141 11.572-18.185zm-21.041 0c0-5.656 3.456-10.929 6.69-13.977l1.367-1.289-1.126-1.5L5.97 2.939l5.445 1.211.457.1.457-.1 5.444-1.211-3.365 4.493-1.126 1.5 1.363 1.292c3.24 3.048 6.7 8.321 6.7 13.977 0 8.256-7.32 15.282-9.469 16.048-2.152-.765-9.472-7.792-9.472-16.048zm11.047 5.56a1.578 1.578 0 10-1.578 1.578 1.578 1.578 0 001.577-1.577z"
              fill="#73c4d7"
              stroke="#04101f"
              strokeWidth=".6"
            />
          </svg>
        </div>
      </div>

      <div
        style={{
          backgroundColor: colors.darkestBlue,
          paddingTop: `${spaceRem}rem`,
          paddingBottom: `${spaceRem}rem`,
          position: "relative",
          height: "100%",
        }}
      >
        <Img
          fluid={props.data.reef?.childImageSharp?.fluid as FluidObject}
          style={{
            position: "absolute",
            pointerEvents: "none",
            width: "60%",
            top: 0,
            left: 0,
          }}
        />

        <Img
          fluid={props.data.jellyfish?.childImageSharp?.fluid as FluidObject}
          style={{
            position: "absolute",
            pointerEvents: "none",
            width: "20%",
            top: "28%",
            left: "10%",
            opacity: 0.08,
            transform: "rotate(35deg)",
          }}
        />

        <Img
          fluid={props.data.jellyfish?.childImageSharp?.fluid as FluidObject}
          style={{
            position: "absolute",
            pointerEvents: "none",
            width: "10%",
            top: 0,
            right: "10%",
            opacity: 0.08,
          }}
        />

        <Img
          fluid={props.data.reef?.childImageSharp?.fluid as FluidObject}
          style={{
            position: "absolute",
            pointerEvents: "none",
            width: "90%",
            bottom: "35%",
            right: "-20%",
            opacity: 0.7,
          }}
        />

        {/* intro */}
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            maxWidth: "66rem",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: `${3 * spaceRem}rem`,
            paddingLeft: `${0.5 * spaceRem}rem`,
            paddingRight: `${0.5 * spaceRem}rem`,
            color: colors.lightBlue,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              margin: `${-0.5 * spaceRem}rem ${-1 * spaceRem}rem`,
            }}
          >
            <div
              style={{
                margin: `${0.5 * spaceRem}rem ${1 * spaceRem}rem`,
                flexBasis: "20rem",
                flexGrow: 1,
              }}
            >
              <h3
                style={{
                  textTransform: "uppercase",
                  fontSize: "0.65rem",
                  fontWeight: 600,
                  color: colors.mediumBlue,
                  letterSpacing: 2,
                  marginBottom: `${0.25 * spaceRem}rem`,
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    props.data.wpPage?.homeCustomFields?.homeIntro
                      ?.homeIntroTitle || "",
                }}
              />

              <h2
                style={{
                  color: colors.white,
                  marginBottom: `${0.5 * spaceRem}rem`,
                }}
                css={(() => {
                  const minFontSizeRem = 1.5;
                  const maxFontSizeRem = 2.25;
                  const vwRangeStartPx = 400;

                  return css`
                    font-size: ${(minFontSizeRem *
                      (remInPx || targetBaseFontSizePx)) /
                      (vwRangeStartPx / 100)}vw;

                    @media (max-width: ${vwRangeStartPx}px) {
                      font-size: ${minFontSizeRem}rem;
                    }

                    @media (min-width: ${vwRangeStartPx *
                        (maxFontSizeRem / minFontSizeRem)}px) {
                      font-size: ${maxFontSizeRem}rem;
                    }
                  `;
                })()}
                dangerouslySetInnerHTML={{
                  __html:
                    props.data.wpPage?.homeCustomFields?.homeIntro
                      ?.homeIntroHeading || "",
                }}
              />

              <div style={{ marginBottom: `${1 * spaceRem}rem` }}>
                <HtmlContent
                  html={
                    props.data.wpPage?.homeCustomFields?.homeIntro
                      ?.homeIntroContent || ""
                  }
                />
              </div>

              <Link
                className="button"
                to={
                  props.data.wpPage?.homeCustomFields?.homeIntro?.homeIntroPage
                    ?.uri || ""
                }
                dangerouslySetInnerHTML={{
                  __html:
                    props.data.wpPage?.homeCustomFields?.homeIntro
                      ?.homeIntroButtonLabel || "",
                }}
                style={{
                  backgroundColor: "transparent",
                  border: `1px solid ${colors.darkBlue}`,
                  color: colors.mediumBlue,
                  display: "inline-block",
                }}
              />
            </div>

            <div
              style={{
                margin: `${0.5 * spaceRem}rem ${1 * spaceRem}rem`,
                flexBasis: "20rem",
                flexGrow: 1,
              }}
            >
              <div
                css={`
                  display: block !important;

                  @media (min-width: 1000px) {
                    display: none !important;
                  }
                `}
              >
                <img src="/platforms.svg" style={{ width: "100%" }} />
              </div>

              <div
                style={{
                  justifyContent: "center",
                  position: "relative",
                  alignItems: "center",
                }}
                css={`
                  display: none !important;

                  @media (min-width: 1000px) {
                    display: flex !important;
                  }
                `}
              >
                {/* tower, single wind turbine, sheds */}
                <motion.div
                  style={{
                    position: "absolute",
                    width: "42%",
                    marginTop: "-16.75%",
                    opacity: platform3Opacity,
                    translateY: platform3TranslateY,
                  }}
                >
                  <Platform3 animationControls={platformPulse} />
                </motion.div>

                {/* city */}

                <motion.div
                  style={{
                    position: "absolute",
                    width: "42%",
                    right: 0,
                    opacity: platform4Opacity,
                    translateY: platform4TranslateY,
                  }}
                >
                  <Platform4 animationControls={platformPulse} />
                </motion.div>

                {/* helicopter, RIB */}

                <motion.div
                  style={{
                    position: "absolute",
                    width: "42%",
                    left: 0,
                    opacity: platform2Opacity,
                    translateY: platform2TranslateY,
                  }}
                >
                  <Platform2 animationControls={platformPulse} />
                </motion.div>

                {/* three wind turbines, fishing boat */}

                <motion.div
                  style={{
                    position: "absolute",
                    width: "42%",
                    marginTop: "13.5%",
                    opacity: platform1Opacity,
                    translateY: platform1TranslateY,
                  }}
                >
                  <Platform1 animationControls={platformPulse} />
                </motion.div>
              </div>
            </div>
          </div>
        </div>

        {/* energy atlas */}
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            maxWidth: "66rem",
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: `${0.5 * spaceRem}rem`,
            paddingRight: `${0.5 * spaceRem}rem`,
            marginBottom: `${3 * spaceRem}rem`,
            color: colors.lightBlue,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap-reverse",
              margin: `${-0.5 * spaceRem}rem ${-1 * spaceRem}rem`,
            }}
          >
            <div
              style={{
                margin: `${0.5 * spaceRem}rem ${1 * spaceRem}rem`,
                flexBasis: "20rem",
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src="/energy-atlas.svg" />
            </div>

            <div
              style={{
                margin: `${0.5 * spaceRem}rem ${1 * spaceRem}rem`,
                flexBasis: "20rem",
                flexGrow: 1,
              }}
            >
              <h3
                style={{
                  textTransform: "uppercase",
                  fontSize: "0.65rem",
                  fontWeight: 600,
                  color: colors.mediumBlue,
                  letterSpacing: 2,
                  marginBottom: `${0.25 * spaceRem}rem`,
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    props.data.wpPage?.homeCustomFields?.homeEnergyAtlas
                      ?.homeEnergyAtlasTitle || "",
                }}
              />

              <h2
                style={{
                  color: colors.white,
                  marginBottom: `${0.5 * spaceRem}rem`,
                }}
                css={(() => {
                  const minFontSizeRem = 1.5;
                  const maxFontSizeRem = 2.25;
                  const vwRangeStartPx = 400;

                  return css`
                    font-size: ${(minFontSizeRem *
                      (remInPx || targetBaseFontSizePx)) /
                      (vwRangeStartPx / 100)}vw;

                    @media (max-width: ${vwRangeStartPx}px) {
                      font-size: ${minFontSizeRem}rem;
                    }

                    @media (min-width: ${vwRangeStartPx *
                        (maxFontSizeRem / minFontSizeRem)}px) {
                      font-size: ${maxFontSizeRem}rem;
                    }
                  `;
                })()}
                dangerouslySetInnerHTML={{
                  __html:
                    props.data.wpPage?.homeCustomFields?.homeEnergyAtlas
                      ?.homeEnergyAtlasHeading || "",
                }}
              />

              <div style={{ marginBottom: `${1 * spaceRem}rem` }}>
                <HtmlContent
                  html={
                    props.data.wpPage?.homeCustomFields?.homeEnergyAtlas
                      ?.homeEnergyAtlasContent || ""
                  }
                />
              </div>

              <Link
                className="button"
                style={{
                  backgroundColor: colors.lightRed,
                  display: "inline-flex",
                  alignItems: "center",
                }}
                to={
                  props.data.wpPage?.homeCustomFields?.homeEnergyAtlas
                    ?.homeEnergyAtlasPage?.uri || ""
                }
              >
                <svg width={21} height={21} viewBox="0 0 21 21">
                  <g transform="translate(-95.5 -79.158)" stroke="#f66">
                    <circle
                      cx={10}
                      cy={10}
                      r={10}
                      transform="translate(96 79.658)"
                      fill="#fff"
                    />
                    <path
                      d="M113.077 88.604h-3a17.328 17.328 0 00-1.091-5.407 7.36 7.36 0 014.091 5.407z"
                      fill="#f66"
                    />
                    <path
                      d="M110.075 91.058h3a7.362 7.362 0 01-4.093 5.407 17.305 17.305 0 001.093-5.407z"
                      fill="#f66"
                    />
                    <path
                      d="M105.829 82.558c.591.572 1.595 2.643 1.8 6.043h-3.6c.205-3.399 1.209-5.471 1.8-6.043z"
                      fill="#f66"
                    />
                    <path
                      d="M107.628 91.058c-.2 3.4-1.209 5.471-1.8 6.043-.591-.572-1.595-2.643-1.8-6.043z"
                      fill="#f66"
                    />
                    <path
                      d="M102.675 83.194a17.306 17.306 0 00-1.091 5.41h-3a7.36 7.36 0 014.091-5.41z"
                      fill="#f66"
                    />
                    <path
                      d="M101.584 91.058a17.328 17.328 0 001.091 5.407 7.36 7.36 0 01-4.093-5.407z"
                      fill="#f66"
                    />
                  </g>
                </svg>

                <span
                  style={{ marginLeft: `${0.5 * spaceRem}rem` }}
                  dangerouslySetInnerHTML={{
                    __html:
                      props.data.wpPage?.homeCustomFields?.homeEnergyAtlas
                        ?.homeEnergyAtlasButtonLabel || "",
                  }}
                />
              </Link>
            </div>
          </div>
        </div>

        {/* programme */}
        <div
          style={{
            position: "relative",
            maxWidth: "66rem",
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: `${0.5 * spaceRem}rem`,
            paddingRight: `${0.5 * spaceRem}rem`,
            color: colors.lightBlue,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3
            style={{
              textTransform: "uppercase",
              fontSize: "0.65rem",
              fontWeight: 600,
              color: colors.mediumBlue,
              letterSpacing: 2,
              marginBottom: `${0.25 * spaceRem}rem`,
            }}
            dangerouslySetInnerHTML={{
              __html:
                props.data.wpPage?.homeCustomFields?.homeProgramme
                  ?.homeProgrammeTitle || "",
            }}
          />
          <h2
            style={{
              color: colors.white,
              marginBottom: `${0.5 * spaceRem}rem`,
              textAlign: "center",
            }}
            css={(() => {
              const minFontSizeRem = 1.5;
              const maxFontSizeRem = 2.25;
              const vwRangeStartPx = 400;

              return css`
                font-size: ${(minFontSizeRem *
                  (remInPx || targetBaseFontSizePx)) /
                  (vwRangeStartPx / 100)}vw;

                @media (max-width: ${vwRangeStartPx}px) {
                  font-size: ${minFontSizeRem}rem;
                }

                @media (min-width: ${vwRangeStartPx *
                    (maxFontSizeRem / minFontSizeRem)}px) {
                  font-size: ${maxFontSizeRem}rem;
                }
              `;
            })()}
            dangerouslySetInnerHTML={{
              __html:
                props.data.wpPage?.homeCustomFields?.homeProgramme
                  ?.homeProgrammeHeading || "",
            }}
          />
          <div>
            <HtmlContent
              html={
                props.data.wpPage?.homeCustomFields?.homeProgramme
                  ?.homeProgrammeIntro || ""
              }
            />
          </div>
        </div>

        <div
          style={{
            position: "relative",
          }}
        >
          <div
            ref={sliderElRef}
            style={{
              overflowX: "auto",
              overflowY: "hidden",
              marginBottom: `${0.5 * spaceRem}rem`,
              paddingTop: `${spaceRem}rem`,
              paddingBottom: `${spaceRem}rem`,
              msOverflowStyle: "none",
            }}
            css={`
              &::-webkit-scrollbar {
                display: none;
              }
            `}
          >
            <ul
              style={{
                display: "flex",
                maxWidth: "66rem",
                marginLeft: "auto",
                marginRight: "auto",
                paddingLeft: `${spaceRem}rem`,
                paddingRight: `${spaceRem}rem`,
              }}
              css={`
                &::after {
                  content: "";
                  flex-basis: ${spaceRem}rem;
                  flex-shrink: 0;
                }

                > li {
                  transition: all 0.2s ease;
                }

                > li:hover {
                  transform: scale(1.1);
                }
              `}
            >
              {props.data.wpPage?.homeCustomFields?.homeProgramme?.homeProgrammeItems?.map(
                (item, i) => (
                  <li
                    key={i}
                    style={{
                      ...(i > 0 && { marginLeft: `${spaceRem}rem` }),
                      flexShrink: 0,
                      width: 275,
                      height: 385,
                      position: "relative",
                    }}
                  >
                    <Link to={item?.homeProgrammeItemsItem?.uri}>
                      <svg
                        viewBox="0 0 280 76"
                        style={{
                          position: "absolute",
                          top: -2,
                          left: -2,
                          width: "103%",
                          zIndex: 5,
                        }}
                      >
                        <defs>
                          <clipPath id="slider_wave__a">
                            <path
                              transform="translate(0 26.706)"
                              fill="#011c34"
                              stroke="#707070"
                              d="M0 0h280v76H0z"
                            />
                          </clipPath>
                          <clipPath id="slider_wave__b">
                            <path
                              transform="translate(0 -.417)"
                              fill="#011c34"
                              d="M0 0h280v101H0z"
                            />
                          </clipPath>
                        </defs>
                        <g
                          transform="translate(0 -26.706)"
                          clipPath="url(#slider_wave__a)"
                        >
                          <g>
                            <g
                              transform="translate(0 3.123)"
                              clipPath="url(#slider_wave__b)"
                            >
                              <g>
                                <path
                                  d="M283.018 70.274C187.261 45.409 94.62 109.489-1.105 85.616L-6.038-9.85c95.758 24.86 188.4-39.215 284.127-15.338q2.462 47.724 4.93 95.462z"
                                  fill={colors.darkestBlue}
                                />
                              </g>
                            </g>
                            <path
                              fill={colors.darkestBlue}
                              d="M0-5.294h280v36H0z"
                            />
                          </g>
                        </g>
                      </svg>

                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          zIndex: 2,
                        }}
                      />

                      <Img
                        fluid={
                          item?.homeProgrammeItemsImage?.localFile
                            ?.childImageSharp?.fluid as FluidObject
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          zIndex: 1,
                        }}
                      />

                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          padding: `${spaceRem}rem`,
                          fontFamily: fontStacks.poppins,
                          color: colors.white,
                          zIndex: 3,
                        }}
                      >
                        {item?.homeProgrammeItemsName}
                      </div>
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "66rem",
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: `${spaceRem}rem`,
            paddingRight: `${spaceRem}rem`,
          }}
        >
          <motion.button
            style={{
              borderRadius: "50%",
              height: `${2 * spaceRem}rem`,
              width: `${2 * spaceRem}rem`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
              cursor: "pointer",
              outline: "none",
            }}
            initial="idle"
            whileHover="hover"
            variants={{
              idle: {
                border: `1px solid ${colors.darkBlue}`,
                backgroundColor: "transparent",
              },
              hover: {
                border: "none",
                backgroundColor: colors.mediumBlue,
              },
            }}
            onClick={() => {
              if (sliderElRef.current) {
                sliderElRef.current.scrollTo({
                  left: sliderElRef.current.scrollLeft - 300,
                  behavior: "smooth",
                });
              }
            }}
          >
            <svg
              width={13.804 * 1.2}
              height={11.486 * 1.2}
              viewBox="0 0 13.804 11.486"
              style={{
                transform: `rotate(180deg)`,
              }}
            >
              <motion.path
                d="M.795 6.344h10.747l-3.966 3.889a.575.575 0 000 .833.575.575 0 00.833 0l4.995-4.878a.488.488 0 00.2-.4.832.832 0 00-.159-.436l-5-5a.6.6 0 00-.436-.159.6.6 0 00-.436.159.575.575 0 000 .833l3.969 3.972H.795a.595.595 0 100 1.19z"
                variants={{
                  idle: {
                    fill: colors.darkBlue,
                    stroke: colors.darkBlue,
                  },
                  hover: {
                    fill: colors.white,
                    stroke: colors.white,
                  },
                }}
                strokeWidth={0.4}
              />
            </svg>
          </motion.button>

          <motion.button
            style={{
              marginLeft: `${0.5 * spaceRem}rem`,
              borderRadius: "50%",
              height: `${2 * spaceRem}rem`,
              width: `${2 * spaceRem}rem`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
              cursor: "pointer",
              outline: "none",
            }}
            initial="idle"
            whileHover="hover"
            variants={{
              idle: {
                border: `1px solid ${colors.darkBlue}`,
                backgroundColor: "transparent",
              },
              hover: {
                border: "none",
                backgroundColor: colors.mediumBlue,
              },
            }}
            onClick={() => {
              if (sliderElRef.current) {
                sliderElRef.current.scrollTo({
                  left: sliderElRef.current.scrollLeft + 300,
                  behavior: "smooth",
                });
              }
            }}
          >
            <svg
              width={13.804 * 1.2}
              height={11.486 * 1.2}
              viewBox="0 0 13.804 11.486"
            >
              <motion.path
                d="M.795 6.344h10.747l-3.966 3.889a.575.575 0 000 .833.575.575 0 00.833 0l4.995-4.878a.488.488 0 00.2-.4.832.832 0 00-.159-.436l-5-5a.6.6 0 00-.436-.159.6.6 0 00-.436.159.575.575 0 000 .833l3.969 3.972H.795a.595.595 0 100 1.19z"
                variants={{
                  idle: {
                    fill: colors.darkBlue,
                    stroke: colors.darkBlue,
                  },
                  hover: {
                    fill: colors.white,
                    stroke: colors.white,
                  },
                }}
                strokeWidth={0.4}
              />
            </svg>
          </motion.button>
        </div>
      </div>
    </>
  );
};

export default Home;

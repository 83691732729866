import React, { FC } from "react";

type Props = {
  color?: string;
  direction: "up" | "down" | "left" | "right";
};

export const Arrow: FC<Props> = props => (
  <svg
    width={13.804}
    height={11.486}
    viewBox="0 0 13.804 11.486"
    style={{
      transform: `rotate(
          ${(props.direction === "up" && -90) ||
            (props.direction === "down" && 90) ||
            (props.direction === "left" && 180) ||
            (props.direction === "right" && 0)}deg)`,
    }}
  >
    <path
      d="M.795 6.344h10.747l-3.966 3.889a.575.575 0 000 .833.575.575 0 00.833 0l4.995-4.878a.488.488 0 00.2-.4.832.832 0 00-.159-.436l-5-5a.6.6 0 00-.436-.159.6.6 0 00-.436.159.575.575 0 000 .833l3.969 3.972H.795a.595.595 0 100 1.19z"
      fill={props.color || "#05101f"}
      stroke={props.color || "#05101f"}
      strokeWidth={0.4}
    />
  </svg>
);
